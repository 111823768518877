<template>
    <div>
        <el-upload class="img-uploader uploader" action="#" multiple accept=".png,.jpeg,.jpg,.png,.bmp"
            :auto-upload="false" :before-upload="beforeUpload" :on-change="uploadImg">
            <span slot="trigger">
                <div class="uploadebox"><i class="el-icon-plus"></i></div>
            </span>
        </el-upload>
        <template v-if="imgURL.length > 0">
            <div class="img-uploader imgbox" v-for="(item, index) in imgURL" :key="index">
                <img :src="item.Value" class="img el-upload" />
                <a href="" @click.prevent="Dele(index)" class="del">X</a>
            </div>
        </template>
        <template v-if="imgUrl.length > 0">
            <div class="img-uploader imgbox" v-for="(item, index) in imgUrl" :key="index">
                <img :src="'data:image/png;base64,' + item" class="img el-upload" />
                <a href="" @click.prevent="del(index)" class="del">X</a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "upload",
    props: ['accept', 'limit', 'guid', 'imgURL'],
    data() {
        return {
            uploadImgUrl: [],
            imgUrl: []
        }
    },
    computed: {
        acceptType() {
            if (this.accept) {
                return this.accept
            } else {
                return ".png,.jpeg,.jpg,.png,.bmp"
            }
        },
        limitSize() {
            if (this.limit) {
                return this.limit
            } else {
                return 10
            }
        },
    },
    mounted() {
        // this.uploadImgUrl = this.imgURL && this.imgURL.length > 0 ? this.imgURL.split(',') : []
    },
    methods: {
        beforeUpload(file) {
            const FileExt = file.name.replace(/.+\./, "").toLowerCase()
            const FileSize = file.size / 1024 / 1024

            if (this.acceptType.indexOf(FileExt) == -1) {
                this.$message.warning(`请上传${this.acceptType}格式的文件`)
                return false
            } else {
                if (FileSize > this.limitSize) {
                    this.$message.warning('上传文件不能超过' + this.limitSize + 'M')
                    return false;
                } else {
                    return true
                }
            }
        },
        uploadImg(file) {
            let self = this;
            if (file.status !== 'ready') {
                return
            }
            let canUpload = self.beforeUpload(file)
            if (canUpload) {
                self.whale.image.compress({
                    file: file.raw,
                    callback(cf) {
                        self.whale.image.toB64({
                            file: cf,
                            callback(b64) {
                                console.log(b64)
                                if (self.guid) {
                                    // self.model.PIC_B64 = b64;
                                    self.whale.remote.getResult({
                                        url: "/api/School/MEET/MinfoApi/UpPicture",
                                        data: { GUID: self.guid, B64: b64, },
                                        completed: function (res) {
                                            self.imgUrl.push(b64)
                                            self.uploadImgUrl.push(res.DATA)
                                            console.log(self.uploadImgUrl)
                                        }
                                    })
                                } else {
                                    self.imgUrl.push(b64)
                                }
                            }
                        })
                    }
                })
            }
        },
        del(index) {
            this.imgUrl.splice(index, 1)
            this.uploadImgUrl.splice(index, 1)
            // this.$emit('getImgUrl', this.uploadImgUrl)
            // let self = this;
            // this.whale.remote.getResult({
            //     url: "/api/School/MEET/MinfoApi/DelPicture",
            //     data: {
            //         GUID: self.$route.query.guid,
            //         // PIC: f.key.DATA,
            //     },
            // });
        },
        Dele(index) {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/School/MEET/MinfoApi/DelPicture",
                data: {
                    GUID: self.guid,
                    PIC: self.imgURL[index].Key,
                },
                completed() {
                    self.imgURL.splice(index, 1)
                }
            });
        }
    }
}
</script>

<style scoped>
.img-uploader {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
}

.uploader {
    border: 1px dashed #409eff;
}

.uploadebox {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;

}

.uploadebox .el-icon-plus {
    font-size: 20px;
}

.imgbox {
    position: relative;
    border: 1px solid #d9d9d9;
    overflow: hidden;

}

.imgbox .del {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 18px;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    display: none;
}

.imgbox:hover {}

.imgbox .del {
    display: block;
}

.img {
    width: 100%;
    height: 100%;
}
</style>